import { NotificationDto } from '@/app/shared/interfaces/notification.interface';
import { ResponsePagingModel } from '@/app/shared/interfaces/pagination.interface';
import { PushNotificationService } from '@/app/shared/services/push-notification.service';
import { SharedService } from '@/app/shared/services/shared.service';
import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
// import { Select } from '@ngxs/store';
import { Subscription } from 'rxjs';
// import { Notification } from '../../../../../shared/interface/notification.interface';
// import { NavService } from '../../../../../shared/services/nav.service';
// import { NotificationState } from '../../../../../shared/state/notification.state';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {

  public unreadNotificationCount: number | undefined;
  public notifications: NotificationDto[] = [];
  public active: boolean = false;
  private subscibe: Subscription | undefined;
  private subscibeChange: Subscription | undefined;
  private formatDateValue = 'dd/MM/yyyy HH:mm:ss';

  constructor(private pushNotiService: PushNotificationService,
    private sharedService: SharedService,
    private router: Router,
  ) {
    this.subscibeChange = this.sharedService.notificationChange$.subscribe(() => {
      this.pushNotiService.getLastestNotifications().subscribe({
        next: (res: ResponsePagingModel<NotificationDto>) => {
          if (res) {
            let data = res.data;
            this.notifications = data;
          }
        },
        error: (err: any) => {
          console.error(err);
        }
      });
      this.pushNotiService.getUnreadNotification().subscribe({
        next: (res: number) => {
          this.unreadNotificationCount = res;
        }
      });
    })
  }

  clickHeaderOnMobile() {
    // this.active = !this.active;
    this.router.navigate(['notification']);
  }
  dateTimeFormat(date: string) {
    if (date) {
      return formatDate(date, this.formatDateValue, 'vi-VN');
    }
    else {
      return "-";
    }
  }
  viewNotificationDetail(noti: NotificationDto) {
    if (!noti.isRead) {
      this.pushNotiService.readNotification(noti.id).subscribe({
        next: (res: boolean) => {
          this.sharedService.notificationChange();
        }
      });
    }

    let url = NotificationDto.getNotiTargetUrl(noti);
    if (url) {
      this.router.navigateByUrl(url);
    }
  }
  ngOnDestroy() {
    this.subscibe?.unsubscribe();
    this.subscibeChange?.unsubscribe();
  }
}
