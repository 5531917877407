import { Component, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { GiftService } from '@/app/shared/services/gift.service';
import { NgbDate, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PointUsage, User } from '@/app/shared/interfaces/user.interface';
import { UserService } from '@/app/shared/services/user.service';
import {MinigameService} from "@/app/shared/services/mingame.service";
import {wheelHistory} from "@/app/shared/interfaces/minigame.interface";

@Component({
  selector: 'app-wheel',
  templateUrl: './wheel.component.html',
  styleUrl: './wheel.component.scss'
})
export class WheelComponent {
  @Input() userName: string;

  private destroy$ = new Subject<void>();

  public user: User;

  giftList: PointUsage[] = [];

  spinHistoryList: wheelHistory[] = [];

  headerColumns = [
    "Thời gian",
    "Tên quà tặng",
    "Mã xác nhận"
  ];

  public startTime: any = {
    date: {} as NgbDate,
    time: {} as NgbTimeStruct,
  };
  public endTime: any = {
    date: {} as NgbDate,
    time: {} as NgbTimeStruct,
  }

  searchBody: {
    limit: number,
    userName: string,
    fromDate?: Date,
    toDate?: Date,
  };

  constructor(
    private userService: UserService,
    private toastService: ToastrService,
    private minigameService: MinigameService,
  ) { }

  ngOnInit() {
    this.userService.getUserAdmin(this.userName).subscribe({
      next: (user: User) => {
        if (user && user.userName) {
          this.user = user;

          this.searchBody = {
            limit: 100,
            userName: this.user.userName,
          };
          this.getSpinHistoryList();
        }
      }
    });
  }


  getSpinHistoryList() {
    this.minigameService.getWheelHistoryList(JSON.stringify(this.searchBody)).subscribe({
      next: (res: any) => {
        if (res && res.data) {
          this.spinHistoryList = res.data.data;
          //set resultString for each item in spinHistoryList to display all rewards name with ', '
          this.spinHistoryList.forEach((item: wheelHistory) => {
            item.resultString = item.result.rewards.map((reward) => reward.rewardName).join(', ');
          });

          //order this.spinHistoryList by time
          this.spinHistoryList.sort((a, b) => {
            return new Date(b.spinDate).getTime() - new Date(a.spinDate).getTime();
          });
        }
      }
    });
  }
  search() {
    let toDay = new Date();
    this.searchBody = {
      limit: 100,
      userName: this.user.userName,
    };

    if (this.startTime.date?.year && !this.endTime.date?.year) {
      this.searchBody.fromDate = new Date(
        this.startTime.date?.year,
        this.startTime.date?.month - 1,
        this.startTime.date?.day,
        0, 0, 0, 0
      );

      this.searchBody.toDate = new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(),
       23, 59, 59, 999
      );
    }

    if (this.endTime.date?.year && !this.startTime.date?.year) {
      this.searchBody.toDate = new Date(
        this.endTime.date?.year,
        this.endTime.date?.month - 1,
        this.endTime.date?.day,
        23, 59, 59, 999
      );
      this.searchBody.fromDate = new Date(1, 1, 1)

    }

    if (this.startTime.date?.year && this.endTime.date?.year) {
      this.searchBody.fromDate = new Date(
        this.startTime.date?.year,
        this.startTime.date?.month - 1,
        this.startTime.date?.day,
        0, 0, 0, 0
      );

      this.searchBody.toDate = new Date(
        this.endTime.date?.year,
        this.endTime.date?.month - 1,
        this.endTime.date?.day,
        23, 59, 59, 999
      );

      if (this.searchBody.fromDate! > this.searchBody.toDate!) {
        this.toastService.error('Ngày bắt đầu và kết thúc không hợp lệ!', '', { positionClass: 'toast-top-right' });
        return;
      }
    }

    this.getSpinHistoryList();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
