import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';


@Component({
  selector: 'app-qr-scanner-detail',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './qr-scanner-detail.component.html',
  styleUrl: './qr-scanner-detail.component.scss',
})
export class QrScannerDetailComponent {
  data: any;
  imgSrc: string;

  // @ViewChild('htmlToImage') htmlToImage!: ElementRef;

  image: string | null = null;

  constructor() {}

  ngOnInit() {
    if (window.history.state.data) {
      this.data = window.history.state.data;
      console.log(this.data);
      this.imgSrc = `https://ecrms-qrs.s3.ap-southeast-1.amazonaws.com/QR_Codes/QRCode_${this.data.userLogin}.png`;
    }
  }

  
}
