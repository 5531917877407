import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@/environments/environment';
import { CreateChildAccountModel, DeliveryAddressModel, Role, User, UserPagingModel } from '../interfaces/user.interface';
import { ResponsePagingModel } from '../interfaces/pagination.interface';
import { Observable } from 'rxjs';
import { BaseResponseDataModel, BaseResponseModel } from '../interfaces/base-response.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) { }

  getUsers(body: UserPagingModel): Observable<ResponsePagingModel<User>>
  getUsers(body: string): Observable<ResponsePagingModel<User>>
  getUsers(body: unknown): Observable<ResponsePagingModel<User>> {
    let request: string;
    if (typeof body === 'object') {
      request = JSON.stringify(body);
    } else {
      request = body as string;
    }
    return this.http.post<ResponsePagingModel<User>>(`${environment.baseURL}user/user/all`, request, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  getUser(username: string) {
    return this.http.get<User>(`${environment.baseURL}user/user/${username}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
  }

  getUserAdmin(username: string) {
    return this.http.get<User>(`${environment.baseURL}user/user/admin/${username}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
  }

  getAllRoles() {
    return this.http.get<Role[]>(`${environment.baseURL}user/role`);
  }

  createUser(body: string) {
    return this.http.post<boolean>(`${environment.baseURL}user/user`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  checkUserExist(username: string) {
    return this.http.get<boolean>(`${environment.baseURL}user/user/validate-user-login?userName=${username}`);
  }

  updateUser(body: string) {
    return this.http.put<boolean>(`${environment.baseURL}user/user`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  updateUserAdmin(body: string) {
    return this.http.put<boolean>(`${environment.baseURL}user/user/admin-update`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  resetUserPass(body : string){
    return this.http.put<boolean>(`${environment.baseURL}user/user/reset-password`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  getUserAddress() {
    return this.http.get<DeliveryAddressModel>(`${environment.baseURL}user/user/address`);
  }

  allowParent(userName: string): Observable<BaseResponseModel> {
    return this.http.post<BaseResponseModel>(`${environment.baseURL}user/user/v1/admin/allow-parent`, JSON.stringify(userName), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  createChildUser(body: CreateChildAccountModel): Observable<BaseResponseDataModel<User>> {
    return this.http.post<BaseResponseDataModel<User>>(`${environment.baseURL}user/user/v1/admin/create-child-user`, JSON.stringify(body), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
