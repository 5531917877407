<div *ngIf="user">
    <div class="bg-white mt-2 mobile-pd">
      <div class="border-0 border-bottom">
        <div class="py-2 d-flex gap-2">
          <div class="col-5 col-sm-5 dp border d-flex align-items-center ps-3" style="padding-right: 2rem;">
            <input class="border-0" placeholder="Từ ngày" name="dp1" style="width: -webkit-fill-available;"
              [(ngModel)]="startTime.date" [ngModelOptions]="{standalone: true}" ngbDatepicker
              #ds1="ngbDatepicker" (click)="ds1.toggle()" />
            <img src="assets/svg/calendar 1.svg" (click)="ds1.toggle()" />
          </div>
          <div class="col-5 col-sm-5 dp border d-flex align-items-center ps-3" style="padding-right: 2rem;">
            <input class="border-0" placeholder="Đến ngày" name="dp2" style="width: -webkit-fill-available;"
              [(ngModel)]="endTime.date" [ngModelOptions]="{standalone: true}" ngbDatepicker
              #ds2="ngbDatepicker" (click)="ds2.toggle()" />
            <img src="assets/svg/calendar 1.svg" (click)="ds2.toggle()" />
          </div>
          <div>
            <button
              type="button"
              class="border-0 bg-transparent p-0"
              (click)="search()"
            >
              <i
                class="ri-search-line d-flex justify-content-center align-items-center search-icon"
              ></i>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="spinHistoryList.length" class="card ps-0 pe-0">
        <div class="card-body">
          <div class="tracking-wrapper table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th *ngFor="let col of headerColumns" scope="col" class="d-none d-xl-table-cell d-lg-table-cell">
                    {{ col }}
                  </th>
                </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of spinHistoryList">
                  <td class="product-image">
                    <div class="d-flex align-items-center gap-3">
                      <div class="d-grid gap-2">
                        <h6 class="d-none d-lg-block d-xl-block">{{ item.spinDate | date: 'dd/MM/yyyy HH:mm:ss' }}</h6>
                      </div>
                    </div>
                  </td>
                <td class="product-image">
                  <div class="d-flex align-items-center gap-3">
                    <div class="d-grid gap-2">
                      <h6 class="d-none d-lg-block d-xl-block">{{ item.resultString }}</h6>
                    </div>
                  </div>
                  </td>
                <td class="product-image">
                  <div class="d-flex align-items-center gap-3">
                    <div class="d-grid gap-2">
                      <h6 class="d-none d-lg-block d-xl-block">{{ item.verificationCode }}</h6>
                    </div>
                  </div>
                </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
