<div class="container p-2">
  <h3>Quét mã QR</h3>

  <!-- devices -->
  <div class="row">
    <div class="col-xs-12 col-md-6 col-lg-4 col-xl-3 my-1">
      <select
        #select1
        (change)="action.playDevice(select1.value)"
        class="form-select form-select-md"
      >
        <option [value]="null" selected>Chọn Camera</option>
        <option
          *ngFor="let c of action.devices.value; let i = index"
          [value]="c.deviceId"
          [selected]="i == action.deviceIndexActive"
        >
          {{ c.label }}
        </option>
      </select>
    </div>
    <!-- 
    <div class="col-xs-12 col-md-6 col-lg-4 col-xl-3 my-1">
      <select #select2 (change)="action.decode = select2.value" class="form-select form-select-md">
        <option value="utf-8" [selected]="action.decode == 'utf-8'">UTF-8</option>
        <option value="iso-8859-15" [selected]="action.decode == 'iso-8859-15'">ISO-8859-15</option>
        <option value="windows-1252" [selected]="action.decode == 'windows-1252'">Windows-1252</option>
        <option value="macintosh" [selected]="action.decode == 'macintosh'">Macintosh</option>
      </select>
    </div> -->
  </div>

  <!-- ngx-scanner-qrcode -->
  <ngx-scanner-qrcode
    #action="scanner"
    [config]="config"
    (event)="onEvent($event, action)"
  ></ngx-scanner-qrcode>

  <!-- data  -->
  <!-- <p class="data">{{ (action.data | async | json) }}</p> -->

  <!-- loading -->
  <p *ngIf="action.isLoading">⌛ Loading...</p>
  <div class="d-flex justify-content-center">
    <!-- start/stop -->
    <button
      class="btn"
      [class.btn-info]="!action.isStart"
      [class.btn-warning]="action.isStart"
      [disabled]="action.isLoading"
      (click)="handle(action, action.isStart ? 'stop' : 'start')"
    >
      <img
        [src]="
          action.isStart
            ? 'https://id1945.github.io/images/svg/camera-off.svg'
            : 'https://id1945.github.io/images/svg/camera-on.svg'
        "
        width="30px"
      />
    </button>

    <!-- play/pause -->
    <button
      class="btn"
      [class.btn-info]="!action.isStart"
      [class.btn-warning]="action.isStart"
      [disabled]="!action.isStart"
      (click)="handle(action, action.isPause ? 'play' : 'pause')"
    >
      <img
        [src]="
          action.isPause
            ? 'https://id1945.github.io/images/svg/play.svg'
            : 'https://id1945.github.io/images/svg/pause.svg'
        "
        width="30px"
      />
    </button>
  </div>

  <div>
    <h3>{{ scanMessage }}</h3>
    <div *ngIf="checkinInfo.userLogin || checkinInfo.name || checkinInfo.numberOfPeople || checkinInfo.zone || checkinInfo.checkinTime">
      <h3>Thông tin quét được</h3>
      <h3 *ngIf="checkinInfo.userLogin">
        Tài khoản: {{ checkinInfo.userLogin }}
      </h3>
      <h3 *ngIf="checkinInfo.name">Tên: {{ checkinInfo.name }}</h3>
      <h3 *ngIf="checkinInfo.numberOfPeople">
        Số người tham dự {{ checkinInfo.numberOfPeople }}
      </h3>
      <h3 *ngIf="checkinInfo!.customerType">Phân nhóm: {{ checkinInfo.customerType }}</h3>
      <h3 *ngIf="checkinInfo!.zone">Khu vực ngồi {{ checkinInfo.zone }}</h3>
      <h3 *ngIf="checkinInfo.checkinTime">
        Thời gian check in {{ checkinInfo.checkinTime | date: 'dd/MM/yyyy HH:mm:ss' }}
      </h3>
    </div>
  </div>
</div>
