import { PagingModelV2 } from "./pagination.interface";

export class SubscribeListModel {
    topicId: string;
    topicName: string;
    isActived: boolean;
}

export class NotificationDto {
    id: string;
    title: string;
    message: string;
    createdAt: string;
    type: NotificationDataType;
    topicIds: string[] | null;
    topicNames: string[] | null;
    dataDetail: string | null;
    data: string | null;
    isRead: boolean;
    readAt: string | null;

    constructor(init?: Partial<NotificationDto>) {
        Object.assign(this, init);
    }

    static getNotiTargetUrl(noti: NotificationDto): string {
        if (noti.data) {
            let data = JSON.parse(noti.data);
            if (noti.type === NotificationDataType.Order) {
                return 'order/details/' + encodeURIComponent(data.OrderId);
            } else if (noti.type === NotificationDataType.User) {
                return 'user/edit/' + encodeURIComponent(data.UserName);
            }
        }
        return '';
    }
}

export enum NotificationDataType {
    User = 1,
    Order = 2,
}

export enum NotificationStatusType
{
    Actived = 1,
    Deleted = 99,
}

export class NotificationRequestModel extends PagingModelV2 {

}

