import { PagingModelV2 } from "./pagination.interface";

export class QrCheckinInfo {
    userLogin?: string;
    name?: string;
    numberOfPeople?: string;
    zone?: string;
    customerType?: string;
    refCode?: string;
    checkinTime?: Date;
}

export class CheckinPagingModel extends PagingModelV2 {
    userLogin?: string = "";
    IsCheckin?: boolean = false;
    region?: string = "";
    customerType?: string = "";
}

export interface QrCheckinInfoModel {
    data: CheckInInfo[];
    total: number;
}

export interface CheckInInfo {
    id: string; 
    userLogin: string;
    participantPhone: string;
    name: string;
    numberOfPeople: string;
    zone: string;
    customerType: string;
    referenceCode?: string;
    checkinTime?: Date;
    region?: string;
  }