import { QueryString } from "@/app/shared/filter";

export class CustomerTypeQueryString extends QueryString {
    public override get isNoValue(): boolean {
        return this._value === '';
    }
    override _fieldModelName: string = "customerType";
    override _fieldName: string = "customerType";
    constructor(value: any = "", sort: number = 0) {
        super();
        this._value = value;
    }
}