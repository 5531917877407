import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { Province, ProvinceExtend, SearchProvinceModel } from "../interfaces/province.interface";
import { Observable, Subject } from "rxjs";
import { PagingModelV2 } from "../interfaces/pagination.interface";

@Injectable({
  providedIn: "root",
})
export class ProvinceService {

  constructor(private http: HttpClient) { }

  getProvinceInfo() {
    return this.http.get<Province[]>(`${environment.baseURL}user/province`);
  }

  getProvinceInfoByDepth(provincecode: number, districtcode: number) {
    return this.http.get<Province[]>(`${environment.baseURL}user/province/province-depth?provincecode=${provincecode}&districtcode=${districtcode}`);
  }

  searchProvinces(request: PagingModelV2) : Observable<SearchProvinceModel> {
    return this.http.post<SearchProvinceModel>(`${environment.baseURL}user/province`, request);
  }

  getProvinceExtends(request: PagingModelV2, selectedProvinces: ProvinceExtend[]): Observable<ProvinceExtend[]> {
    var subProvinceSelected = new Subject<ProvinceExtend[]>();
    this.searchProvinces(request).subscribe({
        next: (res: SearchProvinceModel) => {
            if (res) {
                let result: ProvinceExtend[] = [];
                res.data.forEach(v => {
                    let selected = selectedProvinces.findIndex(m => m.code === v.code) >= 0;
                    let p = { ...v, isSelected: selected } as ProvinceExtend;
                    result.push(p);
                    subProvinceSelected.next(result);
                });
            }
        },
        error: (err: any) => {
            console.log(err);
            subProvinceSelected.next([]);
        }
    });
    return subProvinceSelected.asObservable();
}

}
