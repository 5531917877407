import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModeComponent } from './shared/components/header/widgets/mode/mode.component'
import { isDevMode } from '@angular/core';
import { environment } from '@/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'ecrms-admin-angular';
  private modeComponent: ModeComponent;
  constructor(private translateService: TranslateService) {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }

  ngOnInit(): void {
    if (this.isDevelopmentMode()) {
      document.body.classList.toggle('dark-only');
    } else {

    }
  }

  
  isDevelopmentMode(): boolean {
    return !environment.production && isDevMode();
  }
}

