export interface Paginate {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  paginateRange?: number;
  totalPages?: number;
  startPage?: number;
  endPage?: number;
  startIndex?: number;
  endIndex?: number;
  pages?: number[];
}

export interface PagingModel {
  limit?: number;
  offset?: number;
  search?: string;
}
export class PagingModelV2 {
  limit?: number = 20;
  offset?: number = 0;
  searchTerm?: string = "";
  sort?: string = "";
  sortBy?: string = "";
}

export class ResponsePagingModel<T>{
  data: T[];
  total: number;
}