import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, Output, EventEmitter, ViewChild, Renderer2 } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
// import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { DeleteModalComponent } from "../modal/delete-modal/delete-modal.component";
import { ConfirmationModalComponent } from "../modal/confirmation-modal/confirmation-modal.component";
import { SharedService } from '@/app/shared/services/shared.service';
import { AuthService } from '@/app/shared/services/auth.service';
import { RowAction } from '@/app/shared/interfaces/table-config.interface';
import { IsAuthurization } from '@/app/shared/interfaces/role.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { moveItemInArray } from '@angular/cdk/drag-drop';
// import { TableClickedAction, TableColumn, TableConfig } from '../../../interface/table.interface';
// import { Params } from '../../../interface/core.interface';
// import { AccountState } from '../../../../shared/state/account.state';
// import { Permission } from '../../../..//shared/interface/role.interface';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {

  // @Select(LoaderState.status) loadingStatus$: Observable<boolean>;
  // @Select(AccountState.permissions) permissions$: Observable<Permission[]>;

  @Input() tableConfig: any;
  @Input() hasCheckbox: boolean = false;
  @Input() hasDuplicate: boolean = false;
  @Input() topbar: boolean = true;
  @Input() pagination: boolean = true;
  @Input() loading: boolean = true;
  @Input() dateRange: boolean = false;
  @Input() refreshData: EventEmitter<any>;

  @Output() tableChanged: EventEmitter<any> = new EventEmitter();
  @Output() action = new EventEmitter<any>();
  @Output() rowClicked = new EventEmitter<any>();
  @Output() selectedItems = new EventEmitter<any[]>();
  @Output() searchInput = new EventEmitter<any>();

  @ViewChild("deleteModal") DeleteModal: DeleteModalComponent | undefined;
  @ViewChild("confirmationModal") ConfirmationModal: ConfirmationModalComponent | undefined;

  public tableNames: string[] = [
    'order',
  ]

  public term = new FormControl();
  public rows = [20, 30, 50];
  public tableData: any = {
    'search': '',
    'field': '',
    'sort': '', // current Sorting Order
    'page': 1, // current page number
    'paginate': 20, // Display per page,
  };
  public qPageSize = -1;

  public selected: number[] = [];
  public permissions: number[] = [];

  public hoveredDate: NgbDate | null = null;
  public fromDate: NgbDate | null | undefined;
  public toDate: NgbDate | null | undefined;

  private searchSubject = new Subject<string>();
  private readonly debounceTimeMs = 500;
  public link: string;

  constructor(@Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2, config: NgbRatingConfig,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private sharedService: SharedService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    config.max = 5;     // customize default values of ratings used by this component tree
    config.readonly = true;

    this.term.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(
        (data: string) => {
          this.onChangeTable(data, 'search');
          this.setDateForDatePicker();
        });

    this.searchSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe((searchValue) => {
      this.searchInput.emit(searchValue);
    });

    this.authService.getPermission().subscribe(res => {
      this.permissions = res;
    });
  }

  ngOnInit() {
    if (this.tableConfig.filters?.searchTerm) {
      this.term.setValue(this.tableConfig.filters.searchTerm);
    }

    this.link = window.location.pathname;
    if (this.tableConfig && this.tableConfig.rowActions) {
      for (let i = 0; i < this.tableConfig.rowActions.length; i++) {
        const action = this.tableConfig.rowActions[i];
        const permission = action.permission;
        if (permission && permission.length) {
          if (permission.some((p: number) => !this.permissions.includes(p))) {
            this.tableConfig.rowActions.splice(i, 1);
            i--;
          }
        }
      }
    }
    if (!this.hasPermission(['delete']) && !this.hasDuplicate) {
      this.hasCheckbox = false;
    }

    this.qPageSize = this.sharedService.getPageSize();
    let page = this.sharedService.getCurrentPage();
    if (this.qPageSize > 0) {
      this.tableData['paginate'] = this.qPageSize;
    }
    if (page > 0) {
      this.tableData['page'] = page;
    }

    // if (this.refreshData) {
    //   this.refreshData.subscribe(() => {
    //     this.tableData['page'] = 1;
    //     this.tableChanged.emit(this.tableData);
    //   })
    // }
  }

  setDateForDatePicker() {
    if (this.tableConfig.filters?.fromDate) {
      let from = new Date(this.tableConfig.filters.fromDate);
      this.fromDate = NgbDate.from({
        year: from.getFullYear(),
        month: from.getMonth() + 1,
        day: from.getDate()
      });
    }

    if (this.tableConfig.filters?.toDate) {
      let to = new Date(this.tableConfig.filters.toDate);
      this.toDate = NgbDate.from({
        year: to.getFullYear(),
        month: to.getMonth() + 1,
        day: to.getDate()
      });
    }
  }

  drop(event: any) {
    moveItemInArray(this.tableConfig.data, event.previousIndex, event.currentIndex);
  }

  ngOnChanges() {
    if (this.route.snapshot.queryParams['pageSize'] && typeof parseInt(this.route.snapshot.queryParams['pageSize']) === 'number') {
      this.qPageSize = parseInt(this.route.snapshot.queryParams['pageSize']);
    }
    this.selected = [];
  }

  hasPermission(actions?: string[]) {
    let permission = this.tableConfig?.rowActions?.find((action: any) => actions?.includes(action.actionToPerform))?.permission;
    return IsAuthurization(this.permissions, permission);
  }

  onChangeTable(data: any | any, type: string) {
    if (type === 'sort' && data && data.sortable !== false) {
      switch (data.sort_direction) {
        case 'asc':
          data.sort_direction = 'desc';
          break;
        case 'desc':
          data.sort_direction = 'asc';
          break;
        default:
          data.sort_direction = 'desc';
          break;
      }
      this.tableData['field'] = data.dataField!;
      this.tableData['sort'] = this.tableData['sort'] === 'desc' ? 'asc' : 'desc';
    } else if (type === 'paginate') {
      this.tableData['paginate'] = (<HTMLInputElement>data.target)?.value;
      this.router.navigate([], { relativeTo: this.route, queryParams: { pageSize: this.tableData['paginate'] }, queryParamsHandling: 'merge', replaceUrl: true });
      // Get Pager For Pagination
      const pager = this.sharedService.getPager(this.tableConfig.total, this.tableData["page"], this.tableData['paginate'], this.tableConfig.paginateRange);
      this.tableData["page"] = pager.currentPage;
    } else if (type === 'page') {
      this.tableData['page'] = data;
    } else if (type === 'search') {
      this.tableData["page"] = 1;
      this.tableData['search'] = data;
      if (this.tableConfig.filters?.fromDate) {
        this.tableData['start_date'] = this.tableConfig.filters?.fromDate;
      }
      if (this.tableConfig.filters?.toDate) {
        this.tableData['end_date'] = this.tableConfig.filters?.toDate;
      }
    } else if (type = 'daterange') {
      if (data) {
        this.tableData['start_date'] = data.start_date;
        this.tableData['end_date'] = data.end_date;
      } else {
        delete this.tableData.start_date;
        delete this.tableData.end_date;
      }
    }
    this.renderer.addClass(this.document.body, 'loader-none');
    this.tableChanged.emit(this.tableData);
  }

  onActionClicked(actionType: string, rowData: any, value?: number,index?: number) {

    this.renderer.addClass(this.document.body, 'loader-none');
    if (this.hasPermission([actionType])) {
      rowData[actionType] = value;
      this.action.emit({ actionToPerform: actionType, data: rowData, index: index});
    } else {
      rowData[actionType] = value;
      this.action.emit({ actionToPerform: actionType, data: rowData, index: index});
    }
  }

  onRowClicked(rowData: any): void {
    if (this.hasPermission(['edit', 'view'])) {
      this.rowClicked.emit(rowData);
    }
  }

  checkUncheckAll(event: Event) {
    this.tableConfig?.data!.forEach((item: any) => {
      // if(item.system_reserve != '1') { 
      item.isChecked = (<HTMLInputElement>event?.target)?.checked;
      this.setSelectedItem((<HTMLInputElement>event?.target)?.checked, item?.id);
      // };
    });
  }

  onItemChecked(event: Event) {
    this.setSelectedItem((<HTMLInputElement>event.target)?.checked, (<HTMLInputElement>event.target)?.value);
  }

  setSelectedItem(checked: Boolean, value: any) {
    const index = this.selected.indexOf(value);
    if (checked) {
      if (index == -1) this.selected.push(value)
    } else {
      this.selected = this.selected.filter(id => id != value);
    }
    this.selectedItems.emit(this.selected);
  }

  get deleteButtonStatus() {
    let status = false;
    this.tableConfig?.data?.filter((data: any) => {
      if (this.selected.includes(data?.id)) {
        // const permission = this.tableConfig?.rowActions?.find((action: any) => action.actionToPerform == 'delete')?.permission as string;
        // if(permission && this.permissions?.includes(permission)){
        //   status = true;
        // }
        status = true;
      }
    });
    return status;
  }

  get duplicateButtonStatus() {
    let status = false;
    this.tableConfig?.data?.filter((data: any) => {
      if (this.selected.includes(data?.id)) {
        const permission = this.tableConfig?.rowActions?.find((action: RowAction) => action.actionToPerform.toLowerCase() === 'edit')?.permission;
        status = IsAuthurization(this.permissions, permission);
      }
    });
    return status;
  }

  // For Date Picker

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    let params = {
      start_date: `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`,
      end_date: this.toDate ? `${this.toDate?.year}-${this.toDate?.month}-${this.toDate?.day}` : null
    }
    this.onChangeTable(params, 'daterange')
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'loader-none');
  }

  clearDateRange() {
    this.tableData['page'] = 1;
    this.fromDate = null
    this.toDate = null
    let params = null
    this.tableChanged.emit(this.tableData);
    this.onChangeTable(params, 'daterange')
  }

  input(value: string) {
    if (value && value != this.term.value) {
      this.searchSubject.next(value);
    }
    else if (value != undefined) {
      this.searchInput.emit("");
    }
  }

}
