<div #htmlToImage id="ticket" *ngIf="data" class="global_container_">
  <div class="layer-1-copy-holder">
    <div class="sgn">
      <img class="logo" src="assets/images/ticket-images/logo.png" alt="" width="460" height="219">
      <img class="light" src="assets/images/ticket-images/light.png" alt="" width="850" height="130">
      <p class="text">CHỦ TỊCH HỘI ĐỒNG QUẢN TRỊ - TỔNG GIÁM ĐỐC<br>TẬP ĐOÀN PHÂN PHỐI DƯỢC PHẨM TOÀN CẦU DƯỢC
        VƯƠNG<br>TRÂN TRỌNG KÍNH MỜI</p>
      <p class="text-2">{{data.name}}</p>
      <div class="shape-3"></div>
      <div class="group-2">
        <p class="text-3">THAM DỰ SỰ KIỆN</p>
        <img class="layer-8-copy" src="assets/images/ticket-images/layer_8_copy.png" alt="" width="399" height="47">
      </div>
      <p class="text-4">GALA - CHÀO 2025</p>
      <img class="layer-19" src="assets/images/ticket-images/layer_19.png" alt="" width="1100" height="367">
      <div class="wrapper">
        <p class="text-5">11.1.2025</p>
        <p *ngIf="data.region == 'Hà Nội'" class="text-6"><strong class="text-style">THỜI GIAN:</strong> 17.00 - THỨ BẢY
          11.1.2025<br><strong class="text-style">TẠI:</strong> VINHOMES OCEAN PARK 1 -<br><span
            class="text-style-2">KIÊU KỴ, GIA LÂM,
            HÀ
            NỘI</span></p>
        <p *ngIf="data.region == 'Hồ Chí Minh'" class="text-6"><strong class="text-style">THỜI GIAN:</strong> 17.00 -
          THỨ BẢY
          11.1.2025<br><strong class="text-style">TẠI:</strong> SÂN GOLF TÂN SƠN NHẤT -<br><span
            class="text-style-2">PHƯỜNG 12, GÒ VẤP, TPHCM</span></p>
        <p *ngIf="data.region == 'Hà Nội'" class="text-7">Thư mời dành cho {{data.numberOfPeople}} người &nbsp;|
          &nbsp;<strong class="fw900">HOTLINE:</strong> <strong class="text-style-3">0964870969 - </strong><span
            class="text-style-4">Ms. </span><span class="text-style-5">Tào Thủy</span></p>

        <p *ngIf="data.region == 'Hồ Chí Minh'" class="text-7">Thư mời dành cho {{data.numberOfPeople}} người &nbsp;|
          &nbsp;<strong class="fw900">HOTLINE:</strong> <strong class="text-style-3">0963330101 - </strong><span
            class="text-style-4">Mr. </span><span class="text-style-5">Đoàn</span></p>

        <div class="shape-4"></div>
        <div class="text-8">
          <div>Dược Vương rất mong được đón tiếp Quý vị tại sự kiện,</div>
          <div>cùng nhau khởi đầu một năm mới: ĐỒNG HÀNH - NÂNG TẦM - BỨT PHÁ</div>
        </div>
        <p class="text-9">
          <span class="text-style-6">VŨ MẠNH THẮNG</span><br>
          <span class="text-style-11">CHỦ TỊCH HĐQT - TỔNG GIÁM ĐỐC</span>
        </p>
        <img class="light-copy" src="assets/images/ticket-images/light_copy.png" alt="" width="850" height="130">
        <img class="layer-16" src="assets/images/ticket-images/layer_16.png" alt="" width="638" height="400">
      </div>
    </div>
  </div>
  <div class="group-3">
    <div class="nghe-sy">
      <img class="layer-7" src="assets/images/ticket-images/layer_7.png" alt="" width="419" height="590">
      <div class="group-4">
        <div class="layer-21-copy-holder">
          <img class="layer-22" src="assets/images/ticket-images/layer_22.png" alt="" width="92" height="172">
        </div>
      </div>
      <img class="layer-8" src="assets/images/ticket-images/layer_8.png" alt="" width="384" height="797">
      <img class="layer-20" src="assets/images/ticket-images/layer_20.png" alt="" width="640" height="921">
    </div>
    <img class="layer-18" src="assets/images/ticket-images/layer_18.png" alt="" width="1319" height="804">
    <div class="col">
      <p class="text-10">THÔNG TIN CHECK IN</p>
      <img class="light-copy-2" src="assets/images/ticket-images/light_copy_2.jpg" alt="" width="491" height="75">
      <img class="light-copy-3" [src]="imgSrc" />
    </div>
    <div class="vf3">
      <img class="layer-12" src="assets/images/ticket-images/layer_12.png" alt="" width="515" height="335">
      <img class="layer-73-copy" src="assets/images/ticket-images/layer_73_copy.png" alt="" width="569" height="369">
    </div>
    <div class="xe-vision">
      <img class="layer-14" src="assets/images/ticket-images/layer_14.png" alt="" width="342" height="345">
      <img class="layer-74-copy" src="assets/images/ticket-images/layer_74_copy.png" alt="" width="443" height="447">
    </div>
    <div class="layer-11-holder">
      <span class="text-style-8">giải</span>
      <br class="text-style-7">
      <span class="text-style-8">THƯỞNG</span>
      <br class="text-style-7">
      <span class="text-style-10">MAY</span>
      <br class="text-style-9">
      <span class="text-style-10">MẮN</span>
    </div>
    <div class="group-1">
      NGHỆ SĨ
      <br>KHÁCH
      <br>MỜI
    </div>
  </div>
  <div style="position: absolute; bottom: -225px;">
    <img class="qr-code" src="assets/images/ticket-images/{{data.zone}}.png" alt="">
  </div>
</div>