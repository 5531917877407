<app-page-wrapper [gridClass]="'col-sm-12'" [title]="'Danh sách khách mời tham dự' | translate">


    <div class="row flex-row-reverse mb-3">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <label>{{ 'Trạng thái' | translate }}</label>
            <select2 class="custom-select" [customSearchEnabled]="false" [placeholder]="'Trạng thái' | translate" [value]="checkinState"
                [data]="checkinStatus.length ? checkinStatus : []" (update)="onFitlered($event)"
                [templates]="template">
                <ng-template #template let-data="data">
                    <div class="d-flex align-items-center gap-2 ps-3">
                        <div class="icon {{data.icon}}"></div> {{data.label}}
                    </div>
                </ng-template>
            </select2>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <label>{{ 'Vùng' | translate }}</label>
            <select2 class="custom-select" [customSearchEnabled]="false" [placeholder]="'Chọn vùng' | translate" [value]="selectedRegion"
                [data]="regionList.length ? regionList : []" (update)="onRegionFitlered($event)"
                [templates]="template">
                <ng-template #template let-data="data">
                    <div class="d-flex align-items-center gap-2 ps-3">
                        <div class="icon {{data.icon}}"></div> {{data.label}}
                    </div>
                </ng-template>
            </select2>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <label>{{ 'Phân Loại Khách Mời' | translate }}</label>
            <select2 class="custom-select" [customSearchEnabled]="false" [placeholder]="'Chọn' | translate" [value]="selectedParticipantType"
                [data]="participantType.length ? participantType : []" (update)="onParticipantTypeFitlered($event)"
                [templates]="template">
                <ng-template #template let-data="data">
                    <div class="d-flex align-items-center gap-2 ps-3">
                        <div class="icon {{data.icon}}"></div> {{data.label}}
                    </div>
                </ng-template>
            </select2>
        </div>
    </div>

    <app-table [tableConfig]="tableConfig" [loading]="loading"
        (tableChanged)="onTableChange($event)"
        (searchInput)="onSearch($event)"
        (action)="onActionClicked($event)">
    </app-table>
  </app-page-wrapper>
  <app-confirmation-modal
  #confirmModal
  [message]="'Bạn có chắc muốn cập nhật không?'"
  (confirmed)="checkinManual($event)"
  [title]="'Reset mật khẩu?'"
>
</app-confirmation-modal>