// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseURL: 'https://be.duocvuong.online/',
    URL: 'https://angular.pixelstrap.net/fastkart-admin/assets/data',
    pageTitle: "Admin - Dược Vương",
    firebaseConfig: {
        apiKey: "AIzaSyC12V5CyZ8jxeXncyrSc_GfwAFuifaRTIM",
        authDomain: "tohoochat.firebaseapp.com",
        databaseURL: "https://tohoochat.firebaseio.com",
        projectId: "tohoochat",
        storageBucket: "tohoochat.appspot.com",
        messagingSenderId: "621518159875",
        appId: "1:621518159875:web:f1adfa9460fd3d9c30ce36",
        measurementId: "G-9730EYPH3B",

    },
    vapidKey: "BO85mrhi7p1dgZJfvekdUyDMQE-6vnIr8x5focDGXaVLV1jnE-Nj4epPqse1rK5OvKEBc5zO9uMxXqgAVb1O_qk"
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
