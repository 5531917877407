import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { Configuration, ConfigurationAction, Menu } from "@/app/shared/interfaces/configuration.interface";
import { Observable } from "rxjs";
import { BaseResponseModel } from "../interfaces/base-response.interface";
import { SubscribeListModel } from "../interfaces/notification.interface";

@Injectable({
  providedIn: "root",
})
export class ConfigurationService {

  constructor(private http: HttpClient) {}

  getConfiguration(userName?: string) {
    // return this.http.get<Configuration>(`${environment.baseURL}configuration`);
    if (userName)
      return this.http.get<Configuration>(`${environment.baseURL}configuration/configuration/${userName}`);
    else
      return this.http.get<Configuration>(`${environment.baseURL}configuration/configuration`);
  }

  upsertConfigurationForUser(model: string, action: ConfigurationAction) {
    return this.http.post(`${environment.baseURL}configuration/configuration?action=${action}`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  getSideBar() {
    return this.http.get<Menu[]>(`${environment.baseURL}configuration/configuration/admin-sidebar`);
  }
  getHomeConfiguration() {
    return this.http.get(`${environment.baseURL}configuration/configuration/home-config`);
  }
  getHomeBanner() {
    return this.http.get(`${environment.baseURL}configuration/configuration/home-banner`);
  }

  getVendorBanner() {
    return this.http.get(`${environment.baseURL}configuration/configuration/vendor-banner`);
  }

  getCommentBanner() {
    return this.http.get(`${environment.baseURL}configuration/configuration/comment-banner`);
  }

  getPopupBanner() {
    return this.http.get(`${environment.baseURL}configuration/configuration/popup-banner`);
  }
  getFlashsaleInfo() {
    return this.http.get(`${environment.baseURL}configuration/configuration/flashsale-info`);
  }

  getGiftInfo() {
    return this.http.get<Menu[]>(`${environment.baseURL}configuration/configuration/gift-info`);
  }

  updateHomeConfiguration(model: any) {
    return this.http.post(`${environment.baseURL}configuration/configuration/home-config`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  updateHomeBanner(model: any) {
    return this.http.post(`${environment.baseURL}configuration/configuration/home-banner`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  updateVendorBanner(model: any) {
    return this.http.post(`${environment.baseURL}configuration/configuration/vendor-banner`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  updateCommentBanner(model: any) {
    return this.http.post(`${environment.baseURL}configuration/configuration/comment-banner`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  updatePopupBanner(model: any) {
    return this.http.post(`${environment.baseURL}configuration/configuration/popup-banner`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  updateFlashsaleInfo(model: any) {
    return this.http.post(`${environment.baseURL}configuration/configuration/flashsale-info`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  updateGiftInfo(model: any) {
    return this.http.post(`${environment.baseURL}configuration/configuration/gift-info`, model, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
}
