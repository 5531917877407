import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { Cart } from "../interfaces/cart.interface";

@Injectable({
  providedIn: "root",
})
export class CartService {

  constructor(private http: HttpClient) { }

  updateCart(body: string) {
    return this.http.post(`${environment.baseURL}order/cart`, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  updateCartMany(body: string) {
    return this.http.post(`${environment.baseURL}order/cart/many`, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  getCarts() {
    return this.http.get<Cart>(`${environment.baseURL}order/cart`);
  }

}
