import { QrCheckinInfo } from '@/app/shared/interfaces/qr-checkin.interface';
import { QrCheckinService } from '@/app/shared/services/qr-checkin.service';
import { CommonModule, NgFor } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import {
  ScannerQRCodeConfig,
  ScannerQRCodeResult,
  NgxScannerQrcodeService,
  NgxScannerQrcodeComponent,
  ScannerQRCodeSelectedFiles,
  ScannerQRCodeSymbolType,
  NgxScannerQrcodeModule,
  LOAD_WASM,
} from 'ngx-scanner-qrcode';

// Necessary to solve the problem of losing internet connection
LOAD_WASM().subscribe((res) => {
  console.log('LOAD_WASM', res);
});

@Component({
  selector: 'app-qrscanner',
  standalone: true,
  imports: [NgxScannerQrcodeModule, CommonModule, NgFor],
  templateUrl: './qrscanner.component.html',
  styleUrls: ['./qrscanner.component.scss'],
})
export class QrscannerComponent {
  public config: ScannerQRCodeConfig = {
    constraints: {
      video: {
        width: window.innerWidth,
      },
    },
    // symbolType: [
    //   ScannerQRCodeSymbolType.ScannerQRCode_QRCODE,
    //   ScannerQRCodeSymbolType.ScannerQRCode_I25,
    //   ScannerQRCodeSymbolType.ScannerQRCode_DATABAR,
    //   ScannerQRCodeSymbolType.ScannerQRCode_CODE39,
    // ],
    // isMasked: false,
    // unScan: true,
    // canvasStyles: [
    //   { /* layer */
    //     lineWidth: 1,
    //     fillStyle: '#00950685',
    //     strokeStyle: '#00950685',
    //   },
    //   { /* text */
    //     font: '17px serif',
    //     fillStyle: '#ff0000',
    //     strokeStyle: '#ff0000',
    //   }
    // ],
  };

  public checkinInfo: QrCheckinInfo = new QrCheckinInfo();
  public dataScan: string = '';

  @ViewChild('action') action!: NgxScannerQrcodeComponent;

  public percentage = 80;
  public quality = 100;
  public scanMessage = '';

  constructor(
    private qrcode: NgxScannerQrcodeService,
    private qrCheckinService: QrCheckinService
  ) {}

  ngAfterViewInit(): void {
    this.action.isReady.subscribe((res: any) => {
      this.handle(this.action, 'start');
    });
  }

  public onEvent(e: ScannerQRCodeResult[], action?: any): void {
    // e && action && action.pause();
    this.dataScan = '';
    let data = e[0].value;
    this.dataScan = data;

    this.checkinInfo = new QrCheckinInfo();

    //check data split by ','
    if (!data.includes(',') || data.split(',').length < 2) {
      this.scanMessage = 'QR code không hợp lệ';
      return;
    } else {
      const [userLogin, refCode, name, numberOfPeople, zone, customerType] =
        data.split(',');

      this.handle(action, 'pause');
      this.checkin(userLogin, refCode);
    }
  }

  checkin(userlogin: string, refCode: string) {
    let body = {
      UserLogin: userlogin.trim(),
      RefCode: refCode.trim(),
    };

    this.checkinInfo = new QrCheckinInfo();

    this.qrCheckinService.checkin(JSON.stringify(body)).subscribe((res) => {
      if (res.isSuccess) {
        this.scanMessage = res.message;
        this.checkinInfo.userLogin = res.data.userLogin;
        this.checkinInfo.name = res.data.name;
        this.checkinInfo.numberOfPeople = res.data.numberOfPeople;
        this.checkinInfo.zone = res.data.zone;
        this.checkinInfo.customerType = res.data.customerType;
        this.checkinInfo.refCode = res.data.refCode;
        this.checkinInfo.checkinTime = res.data.checkinTime;
      } else {
        this.scanMessage = res.message;
      }
    });
  }

  public handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const device = devices.find((f) =>
        /back|rear|environment/gi.test(f.label)
      ); // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    };

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe(
        (r: any) => console.log(fn, r),
        alert
      );
    } else {
      action[fn]().subscribe((r: any) => console.log(fn, r), alert);
    }
  }
}
