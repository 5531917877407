import { environment } from "@/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CheckInInfo, QrCheckinInfoModel } from "../interfaces/qr-checkin.interface";

@Injectable({
  providedIn: "root",
})

export class QrCheckinService {
  constructor(private http: HttpClient) {}

  checkin(body: any) {
    return this.http.post<any>(`${environment.baseURL}minigame/checkin`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getCheckinList(body: any) {
    return this.http.post<QrCheckinInfoModel>(`${environment.baseURL}minigame/checkin/get-list`,body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  checkinManual(body: any) {
    return this.http.post<any>(`${environment.baseURL}minigame/checkin/checkin-manual`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}