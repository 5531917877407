import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { BehaviorSubject, Observable, of } from "rxjs";
import { JwtPayload, jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class AuthService {

  constructor(private http: HttpClient) { }

  login(body: string) {
    return this.http.post(`${environment.baseURL}authentication/account`, body, {
      headers: {
        'Content-Type': 'application/json',
        'X-Admin-Site': 'true'
      }
    });
  }

  logout() {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
  }

  refreshToken(refreshToken: string) {
    return this.http.post(`${environment.baseURL}authentication/account/refresh-token`, refreshToken, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  getPermission(): Observable<number[]> {
    let token = localStorage.getItem('token');
    if (!token) {
      return of([]);
    }
    const decoded = jwtDecode<TokenModel>(token);
    if (decoded.Permissions) {
      const permissions = decoded.Permissions.split(',').map(v => parseInt(v)).filter(v => !isNaN(v));
      return of(permissions);
    }
    return of([]);
  }

  hasAdminAccess(): Observable<boolean> {
    let token = localStorage.getItem('token');
    if (!token) {
      return of(false);
    }
    const decoded = jwtDecode<TokenModel>(token);
    if (decoded.HasAdminAccess) {
      return of(true);
    }

    return of(false);
  }

  isLoggedIn(): boolean {
    let token = localStorage.getItem("token");
    if (token) {
      const jwt = TokenModel.parseJwt(token);
      if (jwt && jwt.exp >= Date.now() / 1000) {
        return true;
      }
    }
    return false;
  }
}

export class TokenModel implements JwtPayload {
  iss?: string;
  sub?: string;
  aud?: string[] | string;
  exp?: number;
  nbf?: number;
  iat?: number;
  jti?: string;
  UserName: string;
  Permissions: string;
  Status: string;
  HasAdminAccess: boolean;

  /**
   * Parse JWT
   * @param token 
   * @returns json object
  */
  static parseJwt(token: string) {
    try {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }
    catch {
      return null;
    }
  }
}
