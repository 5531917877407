import { ConfirmationModalComponent } from '@/app/shared/components/ui/modal/confirmation-modal/confirmation-modal.component';
import { CheckinStatusQueryString } from '@/app/shared/filter/product-filter/checkinstatus.querystring';
import { CustomerTypeQueryString } from '@/app/shared/filter/product-filter/customertype.querystring';
import { RegionQueryString } from '@/app/shared/filter/product-filter/region.querystring';
import { SearchUserQueryString } from '@/app/shared/filter/product-filter/search.querystring';
import { CheckinPagingModel } from '@/app/shared/interfaces/qr-checkin.interface';
import { UserPagingModel } from '@/app/shared/interfaces/user.interface';
import { NotificationService } from '@/app/shared/services/notification.service';
import { QrCheckinService } from '@/app/shared/services/qr-checkin.service';
import { QueryStringService } from '@/app/shared/services/querystring.service';
import { SharedService } from '@/app/shared/services/shared.service';
import { SharedModule } from '@/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select2Data } from 'ng-select2-component';
import { debounceTime, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-qr-scanner-list',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './qr-scanner-list.component.html',
  styleUrl: './qr-scanner-list.component.scss',
})
export class QrScannerListComponent {

  onRefreshData = new EventEmitter<any>();
  private changeQueryStringSubcription?: Subscription;
  public searchUserQuery: SearchUserQueryString = new SearchUserQueryString();
  public searchRegionQuery: RegionQueryString = new RegionQueryString();
  public searchCheckinQuery: CheckinStatusQueryString = new CheckinStatusQueryString();
  public searchCustomerTypeQuery: CustomerTypeQueryString = new CustomerTypeQueryString();
  public checkinStatus: Select2Data = [
    {
      value: -1,
      label: "=== Tất cả ===",
      data: {
        icon: 'all',
        label: '=== Tất cả ==='
      }
    },
    {
      value: 0,
      label: "=== Chưa checkin ===",
      data: {
        icon: 'all',
        label: '=== Chưa checkin ==='
      }
    },
    {
      value: 1,
      label: "=== Đã checkin ===",
      data: {
        icon: 'all',
        label: '=== Đã checkin ==='
      }
    }
  ];

  public regionList: Select2Data = [
    {
      value: -1,
      label: "=== Tất cả ===",
      data: {
        icon: 'all',
        label: '=== Tất cả ==='
      }
    },
    {
      value: 0,
      label: "=== Hà Nội ===",
      data: {
        icon: 'all',
        label: '=== Hà Nội ==='
      }
    },
    {
      value: 1,
      label: "=== Hồ Chí Minh ===",
      data: {
        icon: 'all',
        label: '=== Hồ Chí Minh ==='
      }
    }
  ];

  public participantType: Select2Data = [
    {
      value: -1,
      label: "=== Tất cả ===",
      data: {
        icon: 'all',
        label: '=== Tất cả ==='
      }
    },
    {
      value: 0,
      label: "Khách hàng",
      data: {
        icon: 'all',
        label: 'Khách hàng'
      }
    },
    {
      value: 1,
      label: "Nhà cung cấp",
      data: {
        icon: 'all',
        label: 'Nhà cung cấp'
      }
    },
    {
      value: 2,
      label: "Đối Tác",
      data: {
        icon: 'all',
        label: 'Đối Tác'
      }
    },
    {
      value: 3,
      label: "Khách mời",
      data: {
        icon: 'all',
        label: 'Khách mời '
      }
    },
  ];

  @ViewChild('confirmModal') ConfirmModal:
    | ConfirmationModalComponent
    | undefined;
  public tableConfig: any = {
    columns: [
      {
        title: 'Tên đăng nhập',
        dataField: 'userLogin',
        sortable: false,
        sort_direction: 'desc',
      },
      {
        title: 'Cơ sở kinh doanh',
        dataField: 'name',
        sortable: false,
        sort_direction: 'desc',
      },
      {
        title: 'Tên người tham dự',
        dataField: 'participantName',
        sortable: false,
        sort_direction: 'desc',
      },
      {
        title: 'SDT người tham dự',
        dataField: 'participantPhone',
        sortable: false,
        sort_direction: 'desc',
      },
      {
        title: 'Số người tham dự',
        dataField: 'numberOfPeople',
        sortable: false,
        sort_direction: 'desc',
      },
      {
        title: 'Khu vực',
        dataField: 'zone',
        sortable: false,
        sort_direction: 'desc',
      },
      {
        title: 'Phân nhóm',
        dataField: 'customerType',
        sortable: false,
        sort_direction: 'desc',
      },
      {
        title: 'Reference Code',
        dataField: 'referenceCode',
        sortable: false,
        sort_direction: 'desc',
      },
      {
        title: 'Vùng',
        dataField: 'region',
        sortable: false,
        sort_direction: 'desc',
      },
      {
        title: 'Thời gian check in',
        dataField: 'checkinTime',
        sortable: false,
        sort_direction: 'desc',
        type: 'date',
      },
    ],
    rowActions: [
      {
        label: 'Checkin Thủ Công',
        actionToPerform: 'checkinmanual',
        icon: 'ri-red-packet-line',
      },
      {
        label: 'Xem Vé Mời',
        actionToPerform: 'view',
        icon: 'ri-eye-line',
      }
    ],
    rowActionClass: 'md-width',
    filters: {
      searchTerm: this.route.snapshot.queryParams['searchTerm'] || '',
    },
    data: [] as any[],
    total: 0,
    paginateRange: 7,
  };

  body: CheckinPagingModel = {
    limit: 20,
    offset: 0,
    // sort: "asc",
    // sortBy: "isPassedToErp",
  } as CheckinPagingModel;


  public searchValue: string = '';
  public searchRegionValue: string | undefined;
  public searchCheckinValue: string | undefined;
  public searchCustomerTypeValue: string | undefined;

  private searchSubject = new Subject<string>();
  private readonly debounceTimeMs = 500; // Set the debounce time (in milliseconds)
  public loading: boolean = false;
  public userCheckinManual: '';

  checkinState: number = -1;
  selectedRegion: number = -1;
  selectedParticipantType: number = -1;

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private qrCheckinService: QrCheckinService,
    private notifyService: NotificationService,
    private queryStringService: QueryStringService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    let pageSize = this.sharedService.getPageSize();
    if (pageSize > 0) {
      this.body.limit = pageSize;
    }

    let page = this.sharedService.getCurrentPage();
    if (page > 0) {
      this.body.offset = this.body.limit! * (page - 1);
    }

    this.changeQueryStringSubcription =
      this.queryStringService.eventChangeQueryString.subscribe(() => {
        this.fetchingDataProcessor();
      });

    this.route.queryParams.subscribe(async (params) => {
      // if (params['s'] ) {
      //   this.searchValue = params['s'];
      //   this.body.searchTerm = params['s'];
      // }

      if ( params['region'] ) {
        this.searchRegionValue  = params['region'];
        this.body.region = this.searchRegionValue ;
        this.selectedRegion = params['region'] == 'HN' ? 0 : params['region'] == 'HCM' ? 1 : -1;
      }

      // if ( params['checkinStatus']) {
      //   this.searchCheckinValue = params['checkinStatus'];
      //   this.body.IsCheckin = this.searchCheckinValue == 'true' ? true : false;
      // }

      if (params['customerType']) {
        this.searchCustomerTypeValue = params['customerType'];
        this.body.customerType = this.searchCustomerTypeValue;
        this.selectedParticipantType = params['customerType'] == 'Customer' ? 0 : params['customerType'] == 'Supplier' ? 1 : params['customerType'] == 'Vendor' ? 2 : 3;
      }

      this.checkinState = params['IsCheckin'] ? params['IsCheckin'] == 'true' ? 1 : 0 : -1;

    });

    // this.queryStringService.startFetchingData();
    // this.searchSubject
    //   .pipe(debounceTime(this.debounceTimeMs))
    //   .subscribe((searchValue) => {
    //     this.searchUserQuery.value = this.searchValue;
    //     this.queryStringService.changeQueryString(this.searchUserQuery);
    //     this.searchRegionQuery.value = this.searchRegionValue;
    //     this.queryStringService.changeQueryString(this.searchRegionQuery);
    //     this.searchCheckinQuery.value = this.searchCheckinValue;
    //     this.queryStringService.changeQueryString(this.searchCheckinQuery);
    //     this.searchCustomerTypeQuery.value = this.searchCustomerTypeValue;
    //     this.queryStringService.changeQueryString(this.searchCustomerTypeQuery);
    //   });
    if (!this.route.snapshot.queryParams['searchTerm']) {
      this.getData();
    }
  }

  getData() {
    this.body = {... this.body, ... this.sharedService.buildBody(CheckinPagingModel)};
    this.qrCheckinService.getCheckinList(this.body).subscribe((res) => {
      if (res) {

        res.data.forEach((item: any) => {
          if (item.region == 'HN') {
            item.region = 'Hà Nội';
          }
          else if (item.region == 'HCM') {
            item.region = 'Hồ Chí Minh';
          }
        });

        this.tableConfig.data = res.data;
        this.tableConfig.total = res.total;
      }
      else {
        this.tableConfig.data = [];
        this.tableConfig.total = 0;
      }
    });
  }

  onSearch(e: any) {
    // if (e) {
    //   this.sharedService.buildQueryParams({ searchTerm: e});
    // } else {
    //   this.sharedService.buildQueryParams({ searchTerm: null});
    // }

    // this.refreshData();
  }

  onTableChange(data?: any) {
    this.body.limit = Number(data['paginate']);
    if (data['paginate']) {
      this.body.offset = this.body.limit * (data['page'] - 1);
    }
    // if (data['search']) {
    //   this.body.searchTerm = data['search'];
    // }
    // else {
    //   this.body.searchTerm = '';
    // }

    if (data['search']) {
      this.body.searchTerm = data['search'];
      this.sharedService.buildQueryParams({ searchTerm: data['search']});
    } else {
      this.body.searchTerm = undefined;
      this.sharedService.buildQueryParams({ searchTerm: null});
    }
    
    this.refreshData();
  }

  refreshData() {
    setTimeout(() => {
      this.getData();
    }, 10);
  }

  onActionClicked(action: any) {
    // console.log(action);
    if (action.actionToPerform === 'checkinmanual') {
      this.userCheckinManual = action.data.userLogin; // get userLogin
      this.ConfirmModal?.openModal('checkinmanual', action.data, action.data, 'CHECK IN THỦ CÔNG', 'Bạn có chắc chắn muốn check in thủ công cho khách mời ' + action.data.userLogin + ' không?');
    }
    else if (action.actionToPerform === 'view') {
      //route to qr-scanner-detail.component
      console.log(action.data);
      this.router.navigateByUrl(`/qr-scanner/detail`, {
        state: { data: action.data }
      });
    }
  }

  checkinManual(action: any) {
    if (action) {
      this.loading = true;

      let bodyCheckinManual = {
        UserLogin: this.userCheckinManual
      }

      this.qrCheckinService.checkinManual(JSON.stringify(bodyCheckinManual)).subscribe((res) => {

        if (res.isSuccess) {
          this.getData();
          this.userCheckinManual = '';
          this.notifyService.success(res.message);
        }
        else {
          this.notifyService.error(res.message);
        }
        this.loading = false;
      });
    }
  }

  public onFitlered(data: any) {
    if (data.options && this.checkinState != data.value) {
      switch (data.value) {
        case 0:
          this.body.IsCheckin = false;
          break;
        case 1:
          this.body.IsCheckin = true;
          break;
        default:
          this.body.IsCheckin = undefined;
          break;
      }
      this.checkinState = data.value;
      this.sharedService.buildQueryParams({ IsCheckin: this.body.IsCheckin});
      this.refreshData();
    }
  }

  public onRegionFitlered(data: any) {
    if (data.options && this.selectedRegion != data.value) {
      switch (data.value) {
        case 0:
          this.body.region = 'HN';
          break;
        case 1:
          this.body.region = 'HCM';
          break;
        default:
          this.body.region = undefined;
          break;
      }
      this.sharedService.buildQueryParams({ region: this.body.region});
      this.refreshData();
    }
  }

  fetchingDataProcessor(orderBody?: CheckinPagingModel) {
    if (orderBody) {
      this.copyObjectValue(this.body, orderBody);
    } else {
      this.body.searchTerm = this.searchValue;
    }
  }

  copyObjectValue(target: CheckinPagingModel, source: CheckinPagingModel) {
    target.searchTerm = source.searchTerm;

    
    if (source.limit) {
      target.limit = source.limit;
    }
    if (source.offset) {
      target.offset = source.offset;
    }
    else {
      target.offset = 0;
    }

    if (source.IsCheckin) {
      target.IsCheckin = source.IsCheckin;
    }
    else {
      target.IsCheckin = undefined;
    }

    if (source.region) {
      target.region = source.region;
    }
    else {
      target.region = undefined;
    }

    if(source.searchTerm){
      target.searchTerm = source.searchTerm;
    }
    else{
      target.searchTerm = undefined;
    }

    if (source.customerType) {
      target.customerType = source.customerType;
    }
    else {
      target.customerType = undefined;
    }
  }

  public onParticipantTypeFitlered(data: any) {
    if (data.options && this.selectedParticipantType != data.value) {
      switch (data.value) {
        case 0:
          this.body.customerType = 'Customer';
          break;
        case 1:
          this.body.customerType = 'Supplier';
          break;
        case 2:
          this.body.customerType = 'Vendor';
          break;
        case 3:
          this.body.customerType = 'Guest';
          break;
        default:
          this.body.customerType = undefined;
          break;
      }
      this.sharedService.buildQueryParams({ customerType: this.body.customerType});
      this.refreshData();
    }
  }
}
